import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SubMenu from "./SubMenu";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
} from "react-admin";

// Resources
import users from "../pages/users";
import branchmanagers from "../pages/branchmanagers";
import admins from "../pages/admins";
import branchcrewusers from "../pages/branchcrewusers";
import branch from "../pages/branch";
import district from "../pages/district";
import city from "../pages/city";
import country from "../pages/country";

//Icons
import UsersIcon from "@mui/icons-material/SupervisedUserCircle";
import BranchIcon from "@mui/icons-material/HomeWork";
import DistrictIcon from "@mui/icons-material/LocationOn";
import CityIcon from "@mui/icons-material/LocationCity";
import CountryIcon from "@mui/icons-material/Public";

import { AppState } from "../types";

type MenuName = "menuPoints";

const resourceList = [
  { name: "users", resource: users, icon: UsersIcon },
  { name: "branchmanagers", resource: branchmanagers, icon: UsersIcon },
  { name: "admins", resource: admins, icon: UsersIcon },
  { name: "branchcrewusers", resource: branchcrewusers, icon: UsersIcon },
  { name: "branch", resource: branch, icon: BranchIcon },
];

const pointList = [
  { name: "district", resource: district, icon: DistrictIcon },
  { name: "city", resource: city, icon: CityIcon },
  { name: "country", resource: country, icon: CountryIcon },
];

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuPoints: true,
  });
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {" "}
      <DashboardMenuItem sidebarIsOpen />
      {resourceList.map((i, idx) => (
        <MenuItemLink
          key={idx}
          to={`/${i.name}`}
          primaryText={translate(`resources.${i.name}.name`, {
            smart_count: 2,
          })}
          leftIcon={<i.icon />}
          //   onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      ))}
      <SubMenu
        handleToggle={() => handleToggle("menuPoints")}
        isOpen={state.menuPoints}
        name="pos.menu.points"
        icon={<DistrictIcon />}
        dense={dense}
      >
        {pointList.map((i, idx) => (
          <MenuItemLink
            key={idx}
            to={`/${i.name}`}
            primaryText={translate(`resources.${i.name}.name`, {
              smart_count: 2,
            })}
            leftIcon={<i.icon />}
            //   onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ))}
      </SubMenu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
