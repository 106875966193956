import React, { useState, useEffect, useCallback, CSSProperties } from "react";
import { useVersion, useDataProvider, useTranslate } from "react-admin";

import { useMediaQuery, Theme } from "@material-ui/core";
//import { subDays } from "date-fns";

//Icons
import UsersIcon from "@mui/icons-material/SupervisedUserCircle";
import BranchIcon from "@mui/icons-material/HomeWork";
import DistrictIcon from "@mui/icons-material/LocationOn";
import CityIcon from "@mui/icons-material/LocationCity";
import CountryIcon from "@mui/icons-material/Public";

import Card from "./Card";

import { Customer, Order, Review } from "../types";
import httpClient from "../providers/httpClient";
import { setInterval } from "timers";



interface State {
  table?: any;
  branch?: any;
  product?: any;
  category?: any;
  qrcodeuser?: any;
  cases?: any;
  checks?: any;
  countofsaleproducts?: any;
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {
  const [state, setState] = useState<State>({});
  const version = useVersion();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const fetchDashboard = useCallback(async () => {
    const { data, dashboard }: any = await dataProvider.getOne<any>("dashboard", {
      id: "basic"
    });

    setState((state) => ({ ...state, ...dashboard }));
  }, [dataProvider]);




  useEffect(() => {
    fetchDashboard();
    setInterval(() => fetchDashboard(), 5000)
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const { table, branch, product, category, qrcodeuser, cases, checks, countofsaleproducts } = state

  const leftList = [
    {
      path: "/users",
      label: "users",
      icon: UsersIcon,
      title: translate("QR Kodu Müşerisi"),
      value: qrcodeuser,
    },
    {
      path: "/product",
      label: "product",
      icon: UsersIcon,
      title: translate("Listelenen Toplam Ürün Sayısı"),
      value: product,
    },
    {
      path: "/tables",
      label: "tables",
      icon: UsersIcon,
      title: translate("Masalar (Dolu/Hepsi)"),
      value: `${table?.find((item: any) => item._id == true)?.count || 0}/${table?.reduce((prev: number, next: any) => prev += next.count, 0)}`,
    },
    {
      path: "/products",
      label: "products",
      icon: UsersIcon,
      title: translate("Toplam Satılan Ürün Sayısı"),
      value: countofsaleproducts
    },
  ];

  const rightList = [
    {
      path: "/branch",
      label: "branch",
      icon: BranchIcon,
      title: translate("Toplam İşletme"),
      value: branch,
    },
    {
      path: "/category",
      label: "category",
      icon: UsersIcon,
      title: translate("Listelenen Toplam Kategori Sayısı"),
      value: category,
    },
    {
      path: "/cases",
      label: "cases",
      icon: UsersIcon,
      title: translate("Açık Olan Kasalar (Ciro / Kasa Sayısı)"),
      value: `${cases?.reduce((prev: number, next: any) => prev += next.totalIncome, 0) || 0
    } TL / ${ cases?.length || 0 }`,
    },
    {
      path: "/checks",
      label: "checks",
      icon: UsersIcon,
      title: translate("Toplam Kesilen Adisyon Sayısı"),
      value: checks
    },
  ];
  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        {leftList.map((item, idx) => (
          <Card
            key={idx}
            path={item.path}
            icon={item.icon}
            title={item.title}
            //@ts-ignore
            value={item.value}
          />
        ))}

        <VerticalSpacer />

        {rightList.map((item, idx) => (
          <Card

            key={idx}
            path={item.path}
            icon={item.icon}
            title={item.title}
            value={item.value}
          />
        ))}
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}></div>
      <div style={styles.flex}>
        {leftList.map((item, idx) => (
          <Card
            key={idx}
            path={item.path}
            icon={item.icon}
            title={item.title}
            //@ts-ignore
            value={item.value}
          />
        ))}
        <Spacer />
        {rightList.map((item, idx) => (
          <Card
            key={idx}
            path={item.path}
            icon={item.icon}
            title={item.title}
            value={item.value}
          />
        ))}
      </div>
      <div style={styles.singleCol}></div>
      <div style={styles.singleCol}></div>
    </div>
  ) : (
    <>
      {console.log(state)}
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          {leftList.map((item, idx) => (
            <div style={styles.flex}>
              <Spacer />
              <Card
                key={idx}
                path={item.path}
                icon={item.icon}
                title={item.title}
                //@ts-ignore
                value={item.value}
              />
            </div>
          ))}
          <div style={styles.singleCol}></div>
          <div style={styles.singleCol}></div>
        </div>
        <div style={styles.rightCol}>
          {console.log(rightList)}
          {rightList.map((item, idx) => (
            <div style={styles.flex}>
              <Spacer />
              <Card
                key={idx}
                path={item.path}
                icon={item.icon}
                title={item.title}

                value={item.value}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
