import axios, {AxiosRequestConfig} from "axios"
import { CreateBranchDocumnet, CreateManager } from "./libary/post";
require('dotenv').config()

export default class AxiosPostService{
    public static getConfig() {
        let authToken = localStorage.getItem('token');
        let refreshToken = localStorage.getItem('dgm-refresh-token');
        return {
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'x-refresh': refreshToken 
            },
        } as AxiosRequestConfig
    }

    public static async createBranch(data: CreateBranchDocumnet) {
        let response = await axios.post('/branch', data, AxiosPostService.getConfig());
        return response;
    }

    public static async createManager(data: CreateManager) {
        let response = await axios.post('/branchmanagers', data, AxiosPostService.getConfig());
        return response;
    }
}